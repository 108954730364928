.table-search {
  padding: .5rem;
  background: #f1f1f1;

  .form-group {
    margin-bottom: 0;
  }

  .col-form-label {
    padding: 0;
    margin: 0;
    line-height: 1.9;
  }
}

.spinner-box {
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9;

  &.active {
    display: flex;
  }

  i.material-icons {
    font-size: 3rem;
  }
}

.spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  color: #007bff !important;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.dots-preloader {
  width: 5rem;
  margin: .5rem auto;

  span {
    background: $gray-light;
    display: inline-block;
    width: .6rem;
    height: .6rem;
    margin: 0 .2rem;
    border-radius: 50%;
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: pulse 1s infinite ((1s/3) * $i);
      }
    }
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .2;
  }
}

// Pagination
.pg {
  display: flex;
  width: 15rem;
  margin: 1rem auto;
  align-items: center;

  .pg__btn {
    width: 36px;
    background: none;
    border: none;
    cursor: pointer;
    opacity: .8;
    transition: opacity $transition;

    &:hover {
      opacity: 1;
    }
  }

  .pg-input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pg-input__el {
      margin: 0 .2rem;
    }

    .pg-input-field {
      width: 3rem;
      text-align: center;
      padding: 0;
      font-size: 1rem;
      height: 1.7rem;
    }
  }
}

.modal-dialog {
  .modal-body {
    min-height: 40vh;
    overflow-y: auto;
  }

  .modal-content {
    border-width: 2px;
    max-height: 90vh;
  }

  &.modal_valid {
    .modal-content {
      border-color: green;
    }
  }

  &.modal_invalid {
    .modal-content {
      border-color: red;
    }
  }

  .close-submodal-btn {
    cursor: pointer;
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 1.3rem;
  }
}

.ltr {
  direction: ltr;
}

.long-line-safe {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.ql-picker-label {
  padding-right: 2em !important;
}

.ql-custom .ql-picker-item:before {
  content: attr(data-label);
}

.ql-picker.ql-insertCustomTags .ql-picker-item::before,
.ql-picker.ql-insertCustomTags .ql-picker-label::before {
  content: 'Choose tag'
}

.ql-picker.ql-insertCustomTags [data-value="1"]::before {
  content: 'One'
}

.ql-picker.ql-insertCustomTags [data-value="2"]::before {
  content: 'Two'
}

.ql-insertStar {
  margin-top: -3px;
  margin-left: 3px;
}

@for $i from 1 through 11 {
  #ql-picker-options-#{$i} > span:nth-child(1) {
    pointer-events: none !important;
    cursor: none !important;
    color: $gray-light;
  }
}

//tooltip styles

.popover-preview {
  cursor: context-menu;
  margin-bottom: 0;
}

.tooltip {
  .tooltip-inner {
    background: $gray-light
  }

  .arrow::before {
    border-right-color: $gray-light;
  }
}

.alert-light {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
}

//quill-emoji styles

#tab-toolbar {
  background-color: #f7f7f7;
  padding: 4px 4px 0;
}

#textarea-emoji {
  top: 0 !important;
}

.textarea-emoji-control {
  margin-top: -2.7rem;
  margin-right: 12rem;
}

#tab-panel {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 320px;
  overflow-y: auto;
  font-size: 1.2rem;
  border: 1px solid rgba(0, 0, 0, .15);
  padding: 4px 4px 0;

  span {
    cursor: pointer;
  }
}

// custom-checkbox

.custom-control-label {
  margin-bottom: 0.5rem !important;
  position: absolute;
  right: 0;
}

// wysiwyg
.wysiwyg_number {
  background-color: #1078bb;
  color: white;
  padding: 0 8px;
  border-radius: 4px;
}

.font-size-20 {
  font-size: 20px;
}

.color-red {
  color: red;
}

