.sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25rem;
  height: 100%;
  margin: -50px auto 0;

  .logo {
    height: 100px;
    margin-bottom: 1rem;
  }

  .sign-in-box {
    position: relative;
    width: 100%;
    padding: 1.5rem;
    background: #fff;
    border-radius: 3px;
  }
}