@import url('https://fonts.googleapis.com/css?family=Heebo:300,400,500,700&subset=hebrew');
@import "line-awesome/css/line-awesome.min.css";
@import "~react-quill/dist/quill.snow.css";

$ananas-ripe: #ED8F45;
$ananas: #FECC6D;
$ananas-unripe: #283C00;
$ananas-tops: #497359;
$ananas-tops-darker: darken(#497359, 10%);
$ananas-golden: #F1A930;

$gray-dark: #333;
$gray-light: #777;
$gray-lightest: #ccc;
$green: green;

$transition: .2s ease-in-out;
$transition-long: .4s ease-in-out;

$border-radius: 3px;

@import "layout";
@import "common";

@import "modules/sign-in";
@import "modules/meetings";
@import "modules/settings";
@import "modules/restaurants";
@import "modules/participants";

html {
  direction: rtl;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  background: #f9fafc;
  text-align: right;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

* {
  outline: none !important;
}

// Fixes
.btn .la {
  position: relative;
  top: 1px;
}

.tab-content {
  padding-top: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 55vh;
  height: 100vh;
}

.card {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.modal-footer {
  justify-content: flex-start;
}

.Toastify__toast--success {
  background: #28a745;
}

.react-datepicker-wrapper {
  display: block;
}

label {
  margin-bottom: .2rem;
}

.table td {
  vertical-align: middle;
}

.react-datepicker {
  display: flex !important;
}

.react-datepicker__navigation--previous {
  right: 225px;
}

.react-datepicker__navigation--next {
  right: 0 !important;
}

.btn-link:hover, .btn-link:focus {
  text-decoration: none !important;
}

.btn-icon {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  line-height: 1;
}

.btn-refresh {
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

.ReactTable .-pagination,
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead.-header {
  box-shadow: none;
}

.ReactTable .rt-thead.-header {
  border-bottom: 1px solid $gray-lightest;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer:first-child .rt-resizer {
  display: none;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  align-items: center;
}

.react-datepicker__input-container {
  display: block;

  .form-control {
    width: 100%;
  }
}

.react-datepicker__time-container {
  height: 255px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ced4da;
  border-radius: .25rem .25rem 0 0;
}

.ql-container.ql-snow {
  border: 1px solid #ced4da;
  border-radius: 0 0 .25rem .25rem;
}

.ql-editor {
  text-align: inherit !important;
}

.navbar {
  padding: 0 1rem;
}

// TODO: fix the layout and remove it
.invalid-feedback span {
  display: block;
}