.age-groups {
  .age-groups-form {
    max-height: 0;
    overflow: hidden;
    transition: all $transition-long;
    opacity: 0;

    &.active {
      max-height: 1000px;
      opacity: 1;
    }
  }

  .table {
    margin-top: 1rem;
  }
}

.meeting-cycle {

  .meeting-cycle-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-lightest;
    padding-bottom: 1rem;
  }

  .meeting-cycle-stages-list {
    display: flex;
    margin: 1.5rem 0;
  }

  .meeting-cycle-stage {
    max-width: 20rem;
    border-left: 1px solid $gray-lightest;
    padding-left: 1rem;
    margin-left: 1rem;

    h4 {
      text-align: center;
      font-size: 3rem;
      color: $gray-lightest;
    }

    &:last-child {
      border: none;
    }

    .meeting-cycle-stage-task {
      display: flex;
      margin-bottom: .5rem;
    }

    .meeting-cycle-stage__btn {
      margin-top: 1rem;
    }
  }
}