#root, .app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.app-header {
  position: relative;
  top: 0;
  height: 50px;
  background: linear-gradient(to right, #f37335, #fdaa4f);
  color: $ananas-unripe !important;
  transition: top .3s ease-in-out;
  flex-shrink: 0;

  .navbar {
    justify-content: flex-end;
  }

  .logo {
    float: right;
    width: 230px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      height: 35px;
    }
  }

  .app-header-manager {
    color: #fff;
    display: flex;
    align-items: center;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      font-weight: bold;
      padding: .2rem;
      margin-right: .5rem;
      color: #fff;
    }
  }

  .navbar {
    height: 100%;
  }

  .btn-link {
    color: $ananas-unripe;
  }

  &.app-header_hidden {
    top: -50px;
    transition: none;
  }
}

.app-sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 0;
  width: 230px;
  height: calc(100% - 50px);
  padding: 1rem 0;
  background: #fff;
  transition: right .3s ease-in-out;

  .sidebar-menu {
    list-style-type: none;
    padding: 0;

    li {
      text-align: right;

      h5 {
        margin: 1.5rem 1rem 0;
        padding-bottom: .3rem;
        border-bottom: 3px solid #e9e9e9;
        font-size: 1.4rem;
      }

      a {
        display: block;
        padding: .6rem 1rem;
        color: $ananas-unripe;
        border-right: 4px solid transparent;
        transition: all .2s ease-in-out;
        font-size: 1rem;
        font-weight: bold;

        i {
          position: relative;
          top: 1px;
          font-weight: bold;
        }

        &.active {
          border-color: $ananas-ripe;
        }

        &:hover {
          text-decoration: none;
          background: $ananas-golden;
        }
      }
    }
  }

  &.app-sidebar_hidden {
    right: -230px;
    transition: none;
  }

  &:not(.app-sidebar_hidden) + .app-content {
    margin-right: 230px;
  }

  .copyright {
    margin-top: auto;
    text-align: center;
    font-size: .8rem;
    color: #ccc;
  }
}

.app-content {
  padding: 1.5rem;
  overflow-x: auto;
  flex-grow: 1;
  position: relative;

  .page-title {
    color: $ananas-tops-darker;
    padding-bottom: .5rem;
    margin-bottom: 1rem;

    &:after {
      content: '';
      display: block;
      height: 5px;
      width: 100%;
      background: #e9e9e9;
    }

    .page-title-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2.3rem;
      height: 2.3rem;
      margin-right: .5rem;
      color: $ananas-tops;
      font-size: 2rem;

      &:hover, &:focus {
        box-shadow: none;
        background: none;
        color: $ananas-tops-darker;
      }
    }
  }
}

.error-page {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: $ananas-golden;
  color: #fff;

  &.error-page_not-found {
    background: none;
    color: $ananas-unripe;
  }

  .logo {
    margin-bottom: 2rem;

    img {
      height: 100px;
    }
  }

  .error-page-title {
    font-size: 34px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 2rem;
  }

  .error-page-subtitle {
    font-size: 22px;
    font-weight: bold;
  }
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f7f7f7;
}

::-webkit-scrollbar-thumb {
  background: #929292;
  transition: all $transition;
}

::-webkit-scrollbar-thumb:hover {
  background: #dadada;
}