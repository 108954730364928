.meeting-card {
  position: relative;
  width: 20rem;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border: none;
  transition: all $transition;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.meeting-card_completed {
    background: #f1f1f1;

    .meeting-card-meta {
      background: none;
    }

    .meeting-card-header {
      .meeting-card-header-title {
        h2 {
          color: #555;
        }
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .meeting-card-header {
    text-align: center;

    .meeting-card-header-title {
      margin: -1.25rem -1.25rem .5rem;
      padding: .4rem;
      background: #c1c1c1;

      h2 {
        font-size: 1.4rem;
        margin: 0;
        color: #333;
        display: inline-block;
        padding: .3rem;
        border-radius: 5px;
      }
    }

    .meeting-card-header-datetime {
      margin: .5rem;
      font-size: 1.1rem;
      font-weight: bold;
      color: $ananas-unripe;
    }
  }

  .meeting-card__sub-title {
    font-size: 1.1rem;
    text-align: center;
  }

  .meeting-card-meta {
    list-style-type: none;
    justify-content: space-between;
    font-size: 1rem;
    margin: 0;
    padding: .5rem;
    background: #f9f9f9;

    .meeting-card-meta__item {
      flex-grow: 1;
      text-align: center;
      font-weight: 500;
      height: 4rem;

      &:last-child {
        padding-left: 0;
        border-left: none;
      }

      i {
        display: block;
        font-size: 1.5rem;
        opacity: .7;
      }
    }
  }

  .meeting-card-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    .meeting-card-content__block {
      position: relative;
      margin: .5rem 0 1rem;
      padding: .5rem 0;

      &:after {
        content: '';
        position: absolute;
        width: 30%;
        height: 1px;
        top: 0;
        left: 50%;
        margin-left: -15%;
        background: $gray-lightest;
      }

      &:first-child {
        padding-bottom: 0;

        &:after {
          content: none;
        }
      }
    }
  }

  .meeting-card-tasks {
    position: relative;
    margin-bottom: 0 !important;
    text-align: initial;
  }

  .meeting-participiants {
    font-size: .8rem;

    .table {
      margin-bottom: 0;
    }
  }
}

.tasks-editor {
  .tasks-editor-stage {
    margin-bottom: 1rem;
  }

  .list-group {
    .list-group-item {
      display: flex;
    }

    .placeholder {
      list-style-type: none;
      background: #f9f9f9;
      height: 3rem;
    }
  }

  .task-check {
    top: 2px;
    margin-right: auto;
  }
}

.tasks-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 130px;
  overflow: hidden;
  overflow-y: auto;

  .tasks-list-item {
    display: flex;
    font-size: .8rem;

    .task-check {
      margin-left: .2rem;
      cursor: inherit;

      &.task-check_centered {
        display: flex;
        justify-content: center;
      }
    }

    &.tasks-list-item_completed {
      i {
        color: $green;
      }
    }
  }
}

.task-check {
  position: relative;
  cursor: pointer;

  i {
    font-size: 1.25rem;
  }

  &:after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    top: 5px;
    left: 5px;
    background: #fff;
  }

  &.task-check_completed:after {
    display: none;
  }

  &.task-check_static {
    cursor: initial;
  }
}

.time-select {
  & > div {
    position: relative;

    &:first-child:after {
      content: ':';
      position: absolute;
      left: -9px;
      top: 7px;
    }
  }
}

.meeting-events-list {
  border-bottom: 1px solid $gray-lightest;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .meeting-events__empty {
    text-align: center;
    color: $gray-light;
  }

  .meeting-events-list-table {
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.meeting-event-form {
  max-width: 30rem;
  margin: auto;
}

//style of react-select
.react-select__menu-list {
  height: 8rem;
  overflow-y: hidden;
}