.participants {
  .page-title {
    .page-title__icon {
      display: inline-block;
      margin-left: .4rem;

      .la {
        position: relative;
        top: 3px;
      }
    }
  }
}

.participants-search {
  position: relative;

  &.opened {
    .participants-search-dropdown {
      max-height: 30rem;
      opacity: 1;
    }

    .form-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .participants-search-dropdown {
    max-height: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    background: #fff;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 .25rem .25rem;
    overflow: hidden;
    transition: all $transition;
    z-index: 99999;

    .results {
      max-height: 20rem;
      overflow-y: auto;

      ul {
        display: flex;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        transition: background $transition;

        &:hover {
          background: #f9f9f9;
        }


        &:last-child {
          border-bottom: none;
        }

        li {
          width: 33%;
          padding: .5rem;

          b {
            display: block;
            font-size: .9rem;
            padding-bottom: .2rem;
            margin-bottom: .3rem;
            border-bottom: 1px solid #f1f1f1;
          }
        }
      }
    }

  }
}

.participant-messages-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.participant-action-event-meeting {
  & > * {
    direction: rtl;
  }

  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.participant-action-event {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}