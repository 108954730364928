.restaurant-card {
  max-width: 25rem;
  border: 2px solid $gray-light;
  color: $ananas-unripe;
  margin: auto;

  .card-body {
    padding: .5rem;
  }

  .restaurant-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .restaurant-card-header__icon {
      font-size: 2rem;
    }
  }

  .restaurant-card-meta {
    text-align: center;
    color: $gray-light;
  }

  .restaurant-card-contact {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;

    ul {
      list-style-type: none;
      padding: 0 0 .5rem 0;
      margin: 0 0 .5rem 0;
      font-size: .9rem;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
        margin: 0;
        padding: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -1rem;
      width: 1px;
      height: 100%;
      background: $gray-light;
    }
  }
}